/* eslint-disable */
var icon = require('vue-svgicon');
icon.register({
    'tag-fill': {
        width: 16,
        height: 16,
        viewBox: '0 0 16 16',
        data: '<path pid="0" d="M2 1a1 1 0 00-1 1v4.586a1 1 0 00.293.707l7 7a1 1 0 001.414 0l4.586-4.586a1 1 0 000-1.414l-7-7A1 1 0 006.586 1zm4 3.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0"/>',
    },
});
